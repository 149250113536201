//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'FinancingMetrics',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isMoneyDown(index) {
      return index === 0;
    },
  },
  computed: {
    monthly() {
      return {
        months: 24,
        payment: '16.63',
      };
    },
    blocks() {
      return [
        {
          prefix: '$',
          value: '0',
          suffix: ' ',
          text: 'No Money Down',
        },
        {
          prefix: ' ',
          value: '0',
          suffix: '%',
          text: 'APR Financing',
        },
        {
          heading: 'Starting at ',
          prefix: '$',
          value: this.monthly.payment,
          suffix: '/mo',
          text: `For ${this.monthly.months} Months`,
        },
      ];
    },
  },
};
